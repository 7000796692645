import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-exit',
	templateUrl: './exit.component.html',
	styleUrls: ['./exit.component.less']
})
export class ExitComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

	yes() {
		window.location.href = '/';
	}

	no() {
		window.location.href = '/';
	}
}
