import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
	selector: 'app-event',
	templateUrl: './event.component.html',
	styleUrls: ['./event.component.less']
})
export class EventComponent implements OnInit {
	eventTemplate: any = '';

	private events: any = [
		'hg1',
		'hg2',
		'hg3',
	];

	constructor(private http: HttpClient, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
	}

	ngOnInit() {
		const scope = this;
		this.route.params.subscribe(params => {
			if ('code' in params && this.events.indexOf(params.code) != -1) {
				this.http.get(
					'/assets/events/' + params.code + '.html',
					{responseType: 'text'}
				)
				.subscribe((data: any) => scope.eventTemplate = this.sanitizer.bypassSecurityTrustHtml(data));
			}
		});

	}

}
