import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { EventComponent } from './event/event.component';
import { AdvancedComponent } from './advanced/advanced.component';
import { SecurityComponent } from './security/security.component';
import { BootComponent } from './boot/boot.component';
import { ExitComponent } from './exit/exit.component';


const routes: Routes = [
	{ path: '', component: MainComponent },
	{ path: 'event/:code', component: EventComponent },
	{ path: 'advanced', component: AdvancedComponent },
	{ path: 'security', component: SecurityComponent },
	{ path: 'boot', component: BootComponent },
	{ path: 'exit', component: ExitComponent },
];


@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
