import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';

@Component({
	selector: 'app-advanced',
	templateUrl: './advanced.component.html',
	styleUrls: ['./advanced.component.less'],
	preserveWhitespaces: true
})
export class AdvancedComponent implements OnInit {
	v1: any = '5.00';
	v2: any = '3.00';
	v3: any = '3.00';
	v4: any = '12.00';
	v5: any = '18.00';

	constructor() { }

	ngOnInit() {
		const generator = interval(1000);
		generator.subscribe((_val) => {
			this.v1 = (5 + 0.1 - Math.random() * 0.2).toFixed(2);
			this.v2 = (3 + 0.1 - Math.random() * 0.2).toFixed(2);
			this.v3 = (3 + 0.1 - Math.random() * 0.2).toFixed(2);
			this.v4 = (12 + 0.2 - Math.random() * 0.4).toFixed(2);
			this.v5 = (18 + 0.1 - Math.random() * 0.21).toFixed(2);
		});
	}
}
