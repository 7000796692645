import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { EventComponent } from './event/event.component';
import { MainComponent } from './main/main.component';
import { AdvancedComponent } from './advanced/advanced.component';
import { SecurityComponent } from './security/security.component';
import { BootComponent } from './boot/boot.component';
import { ExitComponent } from './exit/exit.component';

@NgModule({
  declarations: [
    AppComponent,
    EventComponent,
    MainComponent,
    AdvancedComponent,
    SecurityComponent,
    BootComponent,
    ExitComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
	HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
