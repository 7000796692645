import { Component } from '@angular/core';
import { interval } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less']
})
export class AppComponent {
	title = 'tape.run';
	code = '0xBAADF00D';

	constructor() {
	}

	ngOnInit() {
		const generator = interval(100);
		generator.subscribe(_val => this.code = this.getCode());
	}

	getCode() {
		return '0xFFFFFFFF'.replace(/F/g, function() {
			return (~~(Math.random()*16)).toString(16).toUpperCase();
		});
	}
}
